import { Icon, TriggerButton } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { forwardRef, InputHTMLAttributes } from 'react';

import { ClassNameProps } from '@ComponentProps';
import { ThemeDefinition } from '@UX/themes/types';

type TextFieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> &
  ClassNameProps & {
    onChange: (value: string) => void;
    value: string;
  };

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className, 'data-id': dataId, id, onChange, value, ...restProps }, ref) => {
    const { t } = useTranslation();

    return (
      <label
        htmlFor={id}
        data-id={dataId}
        className={className}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <input
          data-id={dataId}
          ref={ref}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          sx={(theme: ThemeDefinition) => ({
            ...theme.typography.paragraph.medium,
            border: 'none',
            outline: 'none',
            width: '100%',
            color: 'textDefault',
            // appearance 'none' needed for safari on mobile
            appearance: 'none',
            '::placeholder': {
              color: 'textDimmedmedium',
            },
          })}
          autoComplete="off"
          {...restProps}
        />
        {value !== '' && (
          <TriggerButton
            aria-label={t('remove')}
            data-id="text-field-remove"
            onTrigger={() => onChange('')}
            sx={{
              display: 'flex',
            }}
          >
            <Icon
              name="Markers/ErrorFilled"
              size="16"
            />
          </TriggerButton>
        )}
      </label>
    );
  },
);
