import { SxStyleProp } from 'theme-ui';

import { AnchorPosition } from './types';

const positionStyles: Record<AnchorPosition, SxStyleProp> = {
  center: {
    top: 20,
    left: '50%',
    right: 'auto',
    transform: 'translateX(-50%)',
  },
  'top-center': {
    top: -95,
    transform: 'translateX(-50%) translateY(-55%)',
  },
  'top-left': {
    top: 20,
    left: -12,
    right: 'auto',
    transform: 'none',
  },
  'top-left-nopadding': {
    top: 0,
    left: -12,
    right: 'auto',
    transform: 'none',
  },
  'top-right': {
    top: 20,
    left: 'auto',
    right: -12,
    transform: 'none',
  },
};

export const getPositionStyles = (
  anchorPosition: AnchorPosition | [AnchorPosition?, AnchorPosition?, AnchorPosition?],
): SxStyleProp => {
  if (!Array.isArray(anchorPosition)) {
    return positionStyles[anchorPosition];
  }

  return anchorPosition.reduce<any>(
    (acc, curr) => {
      if (!curr) {
        acc.top.push(null);
        acc.left.push(null);
        acc.right.push(null);
        acc.transform.push(null);

        return acc;
      }

      const styles = positionStyles[curr] as any;
      acc.top.push(styles.top);
      acc.left.push(styles.left);
      acc.right.push(styles.right);
      acc.transform.push(styles.transform);

      return acc;
    },
    {
      top: [],
      left: [],
      right: [],
      transform: [],
    },
  );
};
