import { useTranslation } from '@loveholidays/phrasebook';
import React, { useState, useRef, useEffect, useMemo, memo } from 'react';
import { SxStyleProp } from 'theme-ui';

import { DepartureSection, isAnyAirport } from './DepartureSection';
import { getAirportLabel } from './getAirportLabel';
import {
  innerInputWrapperStyles,
  tagsAndInputWrapperStyles,
} from '../DestinationInput/DestinationSelector';
import { SelectedTags } from '../SelectedTags/SelectedTags';
import { TextField } from '../TextField/TextField';
import { Option } from '../types';
import { AirportWithAvailability } from '@AuroraTypes';
import { unique } from '@Core/helpers/array';
import { Key } from '@Core/Key';
import { normaliseString } from '@Core/string';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

interface DepartureSelectorProps {
  onAnyAirportSelected: () => void;
  mainContentWrapperStyles?: SxStyleProp;
}

export const DepartureSelector: React.FC<DepartureSelectorProps> = memo(
  ({ onAnyAirportSelected, mainContentWrapperStyles }) => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');

    const { departureAirports, setDepartureAirports } = useSearchSelectionStore((state) => state);
    const availableAirports = useSearchAvailabilityStore((state) => state.departureAirports);

    const createOption = ({ available, airport }: AirportWithAvailability) =>
      ({
        value: airport.id,
        label: getAirportLabel(airport),
        secondaryLabel:
          airport.location && `${airport.location?.city}, ${airport.location.country}`,
        available,
        selected: departureAirports.includes(airport.id),
      }) as Option;

    const filteredOptionsBySearchTerm = useMemo(() => {
      const normalisedSearchTerm = normaliseString(searchTerm).toLowerCase();

      const filteredAirports = availableAirports.filter(({ airport }) => {
        // Match airport code
        if (airport.id.toLowerCase().includes(normalisedSearchTerm)) {
          return true;
        }

        // Match airport name
        const normalisedLabel = normaliseString(airport.name).toLowerCase();

        return normalisedLabel.includes(normalisedSearchTerm);
      });

      return filteredAirports.map(createOption);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableAirports, searchTerm, departureAirports]);

    const allOptions = availableAirports.map(createOption);

    const selectedOptions = departureAirports
      .map((id) => allOptions.find((d) => d.value === id))
      .filter(Boolean) as Option[];

    const addDepartureAirport = (id: string) =>
      isAnyAirport(id)
        ? setDepartureAirports([id])
        : setDepartureAirports(unique([...departureAirports.filter((o) => !isAnyAirport(o)), id]));

    const removeDepartureAirport = (id: string) => {
      setDepartureAirports(departureAirports.filter((departureAirport) => departureAirport !== id));
    };

    const onSelect = (id: string, isSelected: boolean) => {
      if (isAnyAirport(id)) {
        onAnyAirportSelected();
      }

      if (isSelected) {
        removeDepartureAirport(id);
      } else {
        addDepartureAirport(id);
      }

      setSearchTerm('');
    };

    useEffect(() => {
      searchInputRef.current?.focus();
    }, []);

    return (
      <section data-id="departure-selector">
        <div sx={tagsAndInputWrapperStyles}>
          <div sx={innerInputWrapperStyles}>
            <SelectedTags
              tags={selectedOptions}
              onTagClick={(id) => {
                setDepartureAirports(
                  departureAirports.filter((departureAirports) => departureAirports !== id),
                );
              }}
            />
            <TextField
              data-id="departure-selector-input"
              ref={searchInputRef}
              sx={{
                flex: 1,
                minWidth: '120px', // Hardcoding minWidth so that the Add another placeholder text is always visible. Need to come up with a better solution if going international again.
              }}
              value={searchTerm}
              onChange={setSearchTerm}
              placeholder={
                selectedOptions.length > 0
                  ? t('searchUi.textPlaceholderFilled')
                  : t('searchUi.departureInput.whereFrom')
              }
              onKeyDown={(e) => {
                if (e.key !== Key.Backspace || e.currentTarget.value.length) {
                  return;
                }

                const lastSelected = selectedOptions[selectedOptions.length - 1];

                if (lastSelected) {
                  removeDepartureAirport(lastSelected.value);
                }
              }}
            />
          </div>
        </div>

        <div sx={mainContentWrapperStyles}>
          <DepartureSection
            options={searchTerm === '' ? allOptions : filteredOptionsBySearchTerm}
            onClick={onSelect}
          />
        </div>
      </section>
    );
  },
);
