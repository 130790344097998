import { ModalHeader } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, useCallback } from 'react';

import { NightsSelector } from './NightsSelector';
import { Popover } from '../Popover/Popover';
import { PopoverOrModal } from '../Popover/PopoverOrModal';
import { SearchInput } from '../SearchInput/SearchInput';
import { ClassNameProps } from '@ComponentProps';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { useSearchSelectionStore } from '@Stores/StoreContext';

export interface NightsInputProps extends Omit<ClassNameProps, 'as'> {
  hasInputTitle?: boolean;
  showInputTitleOnMobile?: boolean;
}

export const NightsInput: React.FC<NightsInputProps> = ({
  hasInputTitle,
  className,
  showInputTitleOnMobile,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen, setModalClose] = useModal();

  const selectedNights = useSearchSelectionStore((state) => state.nights);

  const trigger = (
    <SearchInput
      prefixIconName="Content/Moon"
      {...(hasInputTitle && {
        title: t('facets.duration'),
      })}
      showTitleOnMobile={showInputTitleOnMobile}
      placeholder={t('searchUi.nightsInput.inputLabel')}
      label={t('nightsLabel', { count: selectedNights })}
      onClick={useCallback(() => {
        setModalOpen();
        trackEventClick('search-ui-nights-input');
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])}
      data-id="nights-input"
    />
  );

  return (
    <div className={className}>
      <PopoverOrModal
        isOpen={isModalOpen}
        popover={
          <Popover
            size="Medium"
            isOpen={isModalOpen}
            anchorPosition={hasInputTitle ? 'top-left' : 'top-left-nopadding'}
            trigger={trigger}
            onClickOutside={setModalClose}
            contentStyle={{
              padding: 0,
            }}
          >
            <NightsSelector
              onChange={setModalClose}
              sx={{
                maxHeight: '50vh',
                overflowY: 'auto',
              }}
            />
          </Popover>
        }
        modal={
          <Fragment>
            {trigger}
            <LoadableModal
              show={isModalOpen}
              onClose={setModalClose}
              Header={
                <ModalHeader
                  label={t('searchUi.nightsInput.selectorTitle')}
                  layout="medium"
                  onClose={setModalClose}
                />
              }
              Content={<NightsSelector onChange={setModalClose} />}
              noPadding
              noFooterBorder
            />
          </Fragment>
        }
      />
    </div>
  );
};
