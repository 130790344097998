import { ModalHeader } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, useCallback } from 'react';

import { GuestSelector } from './GuestSelector';
import { useTemporaryRoomsState } from './useTemporaryRoomsState';
import { Popover, PopoverProps } from '../Popover/Popover';
import { PopoverOrModal } from '../Popover/PopoverOrModal';
import { SearchInput } from '../SearchInput/SearchInput';
import { RoomConfiguration } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { addSentryBreadcrumb } from '@Core/addSentryBreadcrumb';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { usePriceQuoteUnit } from '@Core/prices/usePriceQuoteUnit';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { isFamily } from '@Stores/SearchSelectionStore';
import { useSearchSelectionStore } from '@Stores/StoreContext';

interface GuestInputProps extends ClassNameProps {
  hasInputTitle?: boolean;
  showInputTitleOnMobile?: boolean;
  popoverAnchorPosition?: PopoverProps['anchorPosition'];
}

export const GuestInput: React.FC<GuestInputProps> = ({
  className,
  hasInputTitle,
  showInputTitleOnMobile,
  popoverAnchorPosition = 'top-right',
}) => {
  const showTotalPriceForFamilies = useFeatureFlag('ShowTotalPriceForFamilies');
  const { setPriceQuoteUnit } = usePriceQuoteUnit();
  const { t } = useTranslation();
  const [isOpen, setOpen, setClose] = useModal();
  const rooms = useSearchSelectionStore((state) => state.rooms);
  const {
    applyRoomsSelection,
    temporaryRooms,
    changeAdults,
    changeChildren,
    changeChildAge,
    addRoom,
    removeRoom,
  } = useTemporaryRoomsState();

  const party = rooms.reduce(
    (acc, room) => ({
      adults: acc.adults + room.adults,
      children: acc.children + room.childAges.length,
    }),
    {
      adults: 0,
      children: 0,
    },
  );

  const getTravellersLabel = () => {
    const travellers =
      party.children > 0
        ? t('rooms.labels.guest', { count: party.adults + party.children })
        : t('rooms.labels.adult', { count: party.adults });

    return `${t('rooms.labels.room', { count: rooms.length })} / ${travellers}`;
  };

  const trigger = (
    <SearchInput
      data-id="guest-input"
      placeholder={t('rooms.title')}
      onClick={useCallback(() => {
        setOpen();
        trackEventClick('search-ui-guest-input');
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])}
      prefixIconName="Content/CustomerGroup"
      label={getTravellersLabel()}
      {...(hasInputTitle && {
        title: t('facets.rooms'),
      })}
      showTitleOnMobile={showInputTitleOnMobile}
    />
  );

  const content = (
    <GuestSelector
      rooms={temporaryRooms}
      onChildAgeChange={changeChildAge}
      onNumberOfAdultsChange={changeAdults}
      onNumberOfChildrenChange={changeChildren}
      onRemoveRoom={removeRoom}
      onAddRoom={addRoom}
    />
  );

  const footerContent = (
    <div
      sx={{
        display: 'grid',
        gridAutoColumns: ['1fr', 'auto'],
        gridAutoFlow: 'column',
        gap: '2xs',
      }}
    >
      <Button
        data-id="cancel-btn"
        variant="TextSecondary"
        size="48"
        onClick={setClose}
      >
        {t('cancel')}
      </Button>
      <Button
        data-id="done-btn"
        variant="PrimaryDefault"
        size="48"
        onClick={() => {
          const isApplied = applyRoomsSelection();
          if (isApplied) {
            if (
              isFamily(temporaryRooms as unknown as RoomConfiguration[]) &&
              showTotalPriceForFamilies
            ) {
              setPriceQuoteUnit('TOTAL');
            }
            setClose();
            addSentryBreadcrumb('Rooms changed', { rooms });
          }
        }}
      >
        {t('done')}
      </Button>
    </div>
  );

  return (
    <PopoverOrModal
      isOpen={isOpen}
      popover={
        <Popover
          className={className}
          size="Medium"
          isOpen={isOpen}
          anchorPosition={popoverAnchorPosition}
          trigger={trigger}
          onClickOutside={setClose}
          footerContent={footerContent}
        >
          {content}
        </Popover>
      }
      modal={
        <Fragment>
          {trigger}
          <LoadableModal
            show={isOpen}
            data-id="modal-container"
            onClose={setClose}
            Actions={footerContent}
            Header={
              <ModalHeader
                label={t('rooms.contentTitle')}
                layout="medium"
                onClose={setClose}
              />
            }
            Content={content}
          />
        </Fragment>
      }
    />
  );
};
