import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';

import { DepartureSelector } from './DepartureSelector';
import { getAirportLabel } from './getAirportLabel';
import { Popover } from '../Popover/Popover';
import { PopoverOrModal } from '../Popover/PopoverOrModal';
import { useFakeAutoFocusTrigger } from '../useFakeAutoFocusTrigger';
import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { LoadableModal } from '@Components/Modal/LoadableModal';
import { useModal } from '@Components/Modal/useModal';
import { useDepartureAirportsInformation } from '@Components/SearchForm/SearchStoreProperties';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { useSearchSelectionStore } from '@Stores/StoreContext';
import { SearchInput } from '@UX/components/Search/SearchInput/SearchInput';

export interface DepartureInputTriggerProps extends ClassNameProps {
  inputRef?: React.RefObject<HTMLDivElement>;
  open: () => void;
  tags: {
    label: string;
    value: string;
  }[];
  hasInputTitle?: boolean;
  showInputTitleOnMobile?: boolean;
  removeTag?: (value: string) => void;
}

const DefaultTrigger: React.FC<DepartureInputTriggerProps> = memo(
  ({ inputRef, className, open, hasInputTitle, tags, showInputTitleOnMobile, removeTag }) => {
    const { t } = useTranslation();

    return (
      <SearchInput
        ref={inputRef}
        className={className}
        data-id="departure-input"
        placeholder={t('anyAirport')}
        onClick={open}
        tags={tags}
        showTitleOnMobile={showInputTitleOnMobile}
        removeTag={removeTag}
        {...(hasInputTitle && {
          title: t('facets.departureAirport'),
        })}
        {...(tags.length && !hasInputTitle
          ? {
              prefix: t('from'),
            }
          : {
              prefixIconName: 'Content/PlaceAirport',
            })}
      />
    );
  },
);

export interface DepartureInputProps extends ClassNameProps {
  trigger?: React.FC<DepartureInputTriggerProps>;
  hasInputTitle?: boolean;
  includePopoverCtas?: boolean;
  showInputTitleOnMobile?: boolean;
  allowTagsRemoval?: boolean;
}

export const DepartureInput: React.FC<DepartureInputProps> = memo(
  ({
    className,
    hasInputTitle,
    trigger: Trigger = DefaultTrigger,
    includePopoverCtas,
    showInputTitleOnMobile,
    allowTagsRemoval,
  }) => {
    const { t } = useTranslation();
    const [isOpen, setOpen, setClose] = useModal();

    const selectedAirports = useDepartureAirportsInformation();

    const tags = selectedAirports.map(({ airport }) => ({
      label: getAirportLabel(airport),
      value: airport.id,
    }));
    const { departureAirports, setDepartureAirports } = useSearchSelectionStore((state) => state);

    const [savedDepartureAirports, setSavedDepartureAirports] =
      useState<string[]>(departureAirports);
    const onAnyAirportSelected = useCallback(() => {
      setClose();
    }, [setClose]);

    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (isOpen) {
        setSavedDepartureAirports(departureAirports);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const triggerFakeInputFocus = useFakeAutoFocusTrigger(inputRef);

    const onOpen = useCallback(() => {
      setOpen();
      triggerFakeInputFocus();
      trackEventClick('search-ui-departure-input');
    }, [setOpen, triggerFakeInputFocus]);

    const onCancel = useCallback(() => {
      setDepartureAirports(savedDepartureAirports);
      setClose();
    }, [savedDepartureAirports, setClose, setDepartureAirports]);

    const onRemoveTag = useCallback(
      (departureId) => {
        setDepartureAirports(departureAirports.filter((id) => id !== departureId));
      },
      [departureAirports, setDepartureAirports],
    );

    const trigger = (
      <Trigger
        hasInputTitle={hasInputTitle}
        showInputTitleOnMobile={showInputTitleOnMobile}
        inputRef={inputRef}
        className={className}
        open={onOpen}
        tags={tags}
        removeTag={allowTagsRemoval ? onRemoveTag : undefined}
      />
    );

    return (
      <PopoverOrModal
        isOpen={isOpen}
        popover={
          <Popover
            className={className}
            size="Medium"
            isOpen={isOpen}
            anchorPosition={hasInputTitle ? 'top-left' : 'top-left-nopadding'}
            trigger={trigger}
            onClickOutside={setClose}
            contentStyle={{
              paddingX: 'xs',
              paddingTop: 0,
              paddingBottom: 0,
            }}
            footerContent={
              includePopoverCtas && (
                <div
                  sx={{
                    display: 'grid',
                    gridAutoColumns: ['1fr', 'auto'],
                    gridAutoFlow: 'column',
                    gap: '2xs',
                  }}
                >
                  <Button
                    data-id="cancel-btn"
                    variant="TextSecondary"
                    size="48"
                    onClick={onCancel}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    data-id="done-btn"
                    variant="PrimaryDefault"
                    size="48"
                    onClick={setClose}
                  >
                    {t('done')}
                  </Button>
                </div>
              )
            }
          >
            <DepartureSelector
              mainContentWrapperStyles={{
                maxHeight: '50vh',
                overflowY: 'auto',
                marginX: '-xs',
                paddingX: 'xs',
                paddingBottom: 'l',
              }}
              onAnyAirportSelected={onAnyAirportSelected}
            />
          </Popover>
        }
        modal={
          <Fragment>
            {trigger}
            <LoadableModal
              show={isOpen}
              data-id="modal-container"
              onClose={setClose}
              Actions={
                <Fragment>
                  <Button
                    data-id="cancel-btn"
                    variant="TextSecondary"
                    size="48"
                    onClick={onCancel}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    data-id="done-btn"
                    variant="PrimaryDefault"
                    size="48"
                    onClick={setClose}
                  >
                    {t('done')}
                  </Button>
                </Fragment>
              }
              Content={<DepartureSelector onAnyAirportSelected={onAnyAirportSelected} />}
            />
          </Fragment>
        }
      />
    );
  },
);
