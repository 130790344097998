import { Skeleton } from '@loveholidays/design-system';
import React from 'react';

export const LoadingResults = () => (
  <React.Fragment>
    {Array(5)
      .fill('')
      .map((_, index) => (
        <div
          key={index}
          sx={{
            paddingY: '3xs',
          }}
        >
          <Skeleton sx={{ width: '100%' }} />
        </div>
      ))}
  </React.Fragment>
);
