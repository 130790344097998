import { Button, hastToReact, Label, Paragraph } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useEffect } from 'react';

import { maxNumberOfRooms } from './constants';
import { GuestRoom } from './GuestRoom';
import { TemporaryRoom } from './types';
import { RoomErrorMessages } from '@Components/RoomsEditor/RoomErrorMessages';
import { useAppContext } from '@Contexts/contexts';
import { sendEvent } from '@Core/tracking/sendEvent';
import { TrackingEvent } from '@Core/tracking/types';

export interface GuestSelectorProps {
  rooms: TemporaryRoom[];
  onNumberOfAdultsChange: (numberOfAdults: number, roomIndex: number) => void;
  onNumberOfChildrenChange: (numberOfChildren: number, roomIndex: number) => void;
  onChildAgeChange: (roomIndex: number, childIndex: number, childAge: number) => void;
  onRemoveRoom: (roomIndex: number) => void;
  onAddRoom: () => void;
}

export const GuestSelector: React.FC<GuestSelectorProps> = ({
  rooms,
  onRemoveRoom,
  onAddRoom,
  onNumberOfAdultsChange,
  onNumberOfChildrenChange,
  onChildAgeChange,
}) => {
  const { site } = useAppContext();
  const largeGroupPhoneNumber = site.salesPhoneNumbers?.largeGroup;
  const { t } = useTranslation();

  useEffect(() => {
    if (rooms.length === maxNumberOfRooms) {
      sendEvent({
        event: TrackingEvent.formError,
        type: RoomErrorMessages.MAX_ROOMS,
      });
    }
  }, [rooms]);

  return (
    <section data-id="guests-selector">
      <Label
        variant="mediumbold"
        as="p"
        sx={{
          display: ['none', 'block'],
          marginBottom: 's',
        }}
      >
        {t('rooms.contentTitle')}
      </Label>

      {rooms.map((room, roomIndex) => (
        <GuestRoom
          key={`${roomIndex}-${JSON.stringify(room)}`}
          onChildAgeChange={onChildAgeChange}
          onNumberOfAdultsChange={onNumberOfAdultsChange}
          onNumberOfChildrenChange={onNumberOfChildrenChange}
          onRemoveClick={onRemoveRoom}
          room={room}
          roomIndex={roomIndex}
        />
      ))}
      {rooms.length < maxNumberOfRooms && (
        <Button
          data-id="add-another-room-btn"
          variant="Secondary"
          size="36"
          stretch
          onClick={onAddRoom}
        >
          {t('rooms.addAnotherRoom')}
        </Button>
      )}
      {rooms.length === maxNumberOfRooms && largeGroupPhoneNumber?.placementText && (
        <Paragraph variant="small">{hastToReact(largeGroupPhoneNumber?.placementText)}</Paragraph>
      )}
    </section>
  );
};
