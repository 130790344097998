import { Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { ListOption } from '../SuggestionList/ListOption';
import { Option } from '../types';

interface AccomodationsSectionProps {
  accomodations: Option[];
  onHotelClick: (hotel: any) => void;
}

export const AccomodationsSection: React.FC<AccomodationsSectionProps> = ({
  accomodations,
  onHotelClick,
}) => {
  const { t } = useTranslation();

  return accomodations && accomodations.length > 0 ? (
    <React.Fragment>
      <Label
        variant="small"
        sx={{
          display: 'block',
          marginTop: 'xl',
          marginBottom: '2xs',
        }}
      >
        {t('searchUi.destinationInput.hotelsSectionTitle')}
      </Label>

      <div
        sx={{
          marginX: '-xs',
        }}
      >
        {accomodations.map((accomodation: Option) => (
          <ListOption
            onTrigger={() => {
              onHotelClick(accomodation);
            }}
            key={accomodation.value}
            title={accomodation.label}
            subTitle={accomodation.secondaryLabel}
            contentIcon="Content/PlaceTown"
            image={accomodation.image}
          />
        ))}
      </div>
    </React.Fragment>
  ) : null;
};
