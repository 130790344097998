import { Tag, UnfilterTag } from '@loveholidays/design-system';
import React, { useEffect, useRef } from 'react';

import { Option } from '../types';
import { ClassNameProps } from '@ComponentProps';
import { useFirstMountState } from '@Core/hooks/useFirstMountState';
import { getScrollBehavior } from '@Core/scroll-helpers/getScrollBehavior';

interface SelectedTagsProps extends ClassNameProps {
  tags: Option[] | undefined;
  onTagClick?: (id: string) => void;
}

export const SelectedTags: React.FC<SelectedTagsProps> = ({ tags, onTagClick, className }) => {
  const selectedTagsRef = useRef<HTMLDivElement>(null);
  const isFirstMount = useFirstMountState();
  const tagsJson = JSON.stringify(tags);

  useEffect(() => {
    if (selectedTagsRef.current?.scroll && process.env.NODE_ENV !== 'test') {
      selectedTagsRef.current.scroll({
        left: selectedTagsRef.current.scrollWidth,
        behavior: isFirstMount ? 'auto' : getScrollBehavior(),
      });
    }
  }, [isFirstMount, tagsJson]);

  return tags && tags.length > 0 ? (
    <div
      data-id="selected-tags"
      ref={selectedTagsRef}
      className={[className, 'hide-scrollbars'].filter(Boolean).join(' ')}
      sx={{
        display: 'flex',
        overflowX: 'auto',
      }}
    >
      {tags.map((d) => (
        <span
          sx={{
            marginRight: '3xs',
          }}
          key={d.value}
        >
          {onTagClick ? (
            <UnfilterTag
              data-id="tag"
              onTrigger={(e) => {
                e.stopPropagation();
                onTagClick(d.value);
              }}
              sx={{
                color: 'textDefault',
                svg: {
                  color: 'iconDefault',
                },
              }}
            >
              {d.label}
            </UnfilterTag>
          ) : (
            <Tag data-id="tag">{d.label}</Tag>
          )}
        </span>
      ))}
    </div>
  ) : null;
};
