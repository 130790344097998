import { Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { SxStyleProp } from 'theme-ui';

import { AccomodationsSection } from './AccomodationsSection';
import { DestinationsSection } from './DestinationsSection';
import { LoadingResults } from './LoadingResults';
import { SelectedTags } from '../SelectedTags/SelectedTags';
import { TextField } from '../TextField/TextField';
import { Option } from '../types';
import { isAnyDestination } from '@Client/utils/isAnyDestination';
import { unique } from '@Core/helpers/array';
import { useUpdateEffect } from '@Core/hooks/useUpdateEffect';
import { Key } from '@Core/Key';
import { useSearchSelectionStore } from '@Stores/StoreContext';

export const tagsAndInputWrapperStyles: SxStyleProp = {
  paddingY: '2xs',
  borderBottomWidth: 'outlinedStrokeWeight',
  borderBottomStyle: 'solid',
  borderBottomColor: 'strokeLightneutral',
  position: 'sticky',
  top: 0,
  zIndex: 'stickyContent',
  backgroundColor: 'backgroundWhite',
  marginX: '-xs',
  paddingX: '2xs',
  borderTopLeftRadius: '12',
  borderTopRightRadius: '12',
};

export const innerInputWrapperStyles: SxStyleProp = {
  minHeight: '48px',
  display: 'flex',
  padding: '3xs',
  paddingY: '4xs',
  border: '2px solid',
  borderColor: 'strokePrimary',
  borderRadius: '8',
  alignItems: 'center',
};

interface DestinationSelectorProps {
  onHotelClick: (option: Option) => void;
  onChangeSearchTerm: (searchTerm: string) => void;
  closeModal: () => void;
  searchTerm: string;
  selectedDestinationOptions: Option[];
  isLoading: boolean;
  lookupResults: Option[];
  mainContentWrapperStyles?: SxStyleProp;
}

export const DestinationSelector: React.FC<DestinationSelectorProps> = memo(
  ({
    onHotelClick,
    onChangeSearchTerm,
    closeModal,
    searchTerm,
    selectedDestinationOptions,
    isLoading,
    lookupResults,
    mainContentWrapperStyles,
  }) => {
    const { t } = useTranslation();
    const searchInputRef = useRef<HTMLInputElement>(null);

    const [destinationIds, setDestinationIds] = useSearchSelectionStore((state) => [
      state.destinationIds,
      state.setDestinationIds,
    ]);

    const { accomodations, destinations } = useMemo(() => {
      return {
        accomodations: lookupResults.filter((l) => l?.type === 'accomodation'),
        destinations: lookupResults.filter((l) => l?.type !== 'accomodation'),
      };
    }, [lookupResults]);

    const addDestinationId = (id: string) =>
      isAnyDestination(id)
        ? setDestinationIds([id])
        : setDestinationIds(unique([...destinationIds.filter((o) => !isAnyDestination(o)), id]));

    const removeDestinationId = (id: string) =>
      !isAnyDestination(id) &&
      setDestinationIds(destinationIds.filter((destinationId) => destinationId !== id));

    const handleHotelClick = (option: Option) => {
      if (option.type !== 'accomodation') {
        return;
      }

      onHotelClick(option);
    };

    const onSelect = (id: string, isSelected: boolean) => {
      if (isSelected) {
        removeDestinationId(id);
      } else {
        addDestinationId(id);
      }

      onChangeSearchTerm('');
    };

    useEffect(() => {
      searchInputRef.current?.focus();
    }, []);

    useUpdateEffect(() => {
      if (destinationIds.length === 1 && isAnyDestination(destinationIds[0])) {
        closeModal();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destinationIds]);

    return (
      <section data-id="destination-selector">
        <div sx={tagsAndInputWrapperStyles}>
          <div sx={innerInputWrapperStyles}>
            <SelectedTags
              tags={selectedDestinationOptions}
              onTagClick={(removedId) =>
                setDestinationIds(
                  destinationIds.filter((destinationId) => destinationId !== removedId),
                )
              }
            />
            <TextField
              ref={searchInputRef}
              data-id="destination-selector-input-field"
              sx={{
                flex: 1,
                minWidth: '120px', // Hardcoding minWidth so that the Add another placeholder text is always visible. Need to come up with a better solution if going international again.
              }}
              onChange={onChangeSearchTerm}
              value={searchTerm}
              placeholder={
                selectedDestinationOptions.length > 0
                  ? t('searchUi.textPlaceholderFilled')
                  : t('searchUi.destinationInput.textPlaceholderEmpty')
              }
              onKeyDown={(e) => {
                if (e.key !== Key.Backspace || e.currentTarget.value.length) {
                  return;
                }

                const lastSelected =
                  selectedDestinationOptions[selectedDestinationOptions.length - 1];

                if (lastSelected) {
                  removeDestinationId(lastSelected.value);
                }
              }}
            />
          </div>
        </div>
        <div sx={mainContentWrapperStyles}>
          {isLoading && <LoadingResults />}

          {!isLoading &&
            (lookupResults.length === 0 ? (
              <Label
                sx={{
                  display: 'block',
                  marginTop: 'xl',
                }}
                variant="medium"
              >
                {t('noMatchesFound')}
              </Label>
            ) : (
              <div data-id="destination-results">
                <DestinationsSection
                  destinations={destinations}
                  onClick={onSelect}
                />

                <AccomodationsSection
                  accomodations={accomodations}
                  onHotelClick={handleHotelClick}
                />
              </div>
            ))}
        </div>
      </section>
    );
  },
);
