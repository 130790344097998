import { RefObject, useCallback } from 'react';

import { isIOS } from '@Client/utils/isIOS';

/**
 * Safari on iOS ignores autofocus for inputs and will only trigger the keyboard
 * when a user interacts with the form.
 * This is a hack to create a fake input and focus on that first before then
 * triggering a focus in javascript on the target input.
 */
export function useFakeAutoFocusTrigger(rootRef: RefObject<HTMLElement>) {
  return useCallback(() => {
    if (isIOS()) {
      const fakeInput = document.createElement('input');
      fakeInput.setAttribute('type', 'text');
      fakeInput.style.position = 'absolute';
      fakeInput.style.opacity = '0';
      fakeInput.style.height = '0';
      fakeInput.style.fontSize = '16px'; // disable auto zoom
      fakeInput.setAttribute('readonly', 'true'); // disables keyboard popup on fake input

      if (rootRef?.current) {
        rootRef.current.prepend(fakeInput);
      }

      // focus so that subsequent async focus will work and keyboard pops up when it does
      fakeInput.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
