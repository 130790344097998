import { Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, useMemo } from 'react';

import { ListOption } from '../SuggestionList/ListOption';
import { Option } from '../types';
import { isAnyDestination } from '@Client/utils/isAnyDestination';
import { orderBy } from '@Core/helpers/orderBy';

interface DestinationsSectionProps {
  destinations: Option[];
  onClick: (id: string, isSelected: boolean) => void;
}

export const DestinationsSection: React.FC<DestinationsSectionProps> = ({
  destinations,
  onClick,
}) => {
  const { t } = useTranslation();

  const sortedDestinations = useMemo(
    () => orderBy(destinations, [(d) => isAnyDestination(d.value), (d) => d.selected], 'desc'),
    [destinations],
  );

  if (!sortedDestinations || sortedDestinations.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <Label
        variant="small"
        sx={{
          display: 'block',
          marginTop: 'l',
          marginBottom: '2xs',
        }}
      >
        {t('searchUi.destinationInput.destinationsSectionTitle')}
      </Label>
      <div
        sx={{
          marginX: '-xs',
        }}
      >
        {sortedDestinations.map((d) => {
          const anyDestination = isAnyDestination(d.value);

          return (
            <ListOption
              onTrigger={() => onClick(d.value, !!d.selected)}
              key={d.value}
              title={d.label}
              subTitle={d.secondaryLabel}
              contentIcon={d.icon ?? (anyDestination ? 'Content/PlaceAnywhere' : 'Content/Place')}
              image={d.image}
              multiSelection={!anyDestination}
              selected={d.selected}
              disabled={!d.available}
            />
          );
        })}
      </div>
    </Fragment>
  );
};
