import React, { ReactElement } from 'react';

import { useBreakpoint } from '@Core/useBreakpoint';

interface PopoverOrModalProps {
  popover: ReactElement;
  modal: ReactElement;
  isOpen: boolean;
}

export const PopoverOrModal: React.FC<PopoverOrModalProps> = ({ popover, modal, isOpen }) => {
  const { isMobile } = useBreakpoint();

  return isOpen && isMobile() ? modal : popover;
};
