import { useTranslation } from '@loveholidays/phrasebook';
import { useState } from 'react';

import { TemporaryRoom } from './types';
import { Maybe } from '@AuroraTypes';
import { useSearchSelectionStore } from '@Stores/StoreContext';

export const cloneRooms = (rooms: TemporaryRoom[]): TemporaryRoom[] =>
  rooms.map((room) => ({
    adults: room.adults,
    childAges: [...room.childAges],
    validationMessage: room.validationMessage,
  }));

export const useTemporaryRoomsState = () => {
  const { t } = useTranslation();
  const [rooms, setRooms] = useSearchSelectionStore((state) => [state.rooms, state.setRooms]);

  const [tempSelectedRooms, setTempSelectedRooms] = useState<TemporaryRoom[]>(() =>
    rooms.map((room) => ({
      adults: room.adults,
      childAges: Array.from(room.childAges || []),
      validationMessage: undefined,
    })),
  );

  const validateRoom = ({ adults, childAges }: TemporaryRoom): Maybe<string> => {
    const areAnyChildrenMissingAges = childAges.some(
      (age) => (typeof age === 'number' && Number.isNaN(age)) || age === undefined,
    );

    const infants = childAges.filter((age) => age !== undefined && age <= 1).length;

    if (areAnyChildrenMissingAges) {
      return t('rooms.errors.noChildAge');
    }

    if (infants > adults) {
      return t('rooms.errors.moreInfantsThanAdults');
    }

    return;
  };

  const changeAdults = (adults: number, roomIndex: number) => {
    const newRooms = cloneRooms(tempSelectedRooms);
    newRooms[roomIndex].adults = adults;
    setTempSelectedRooms(newRooms);
  };

  const changeChildren = (children: number, roomIndex: number) => {
    const newRooms = cloneRooms(tempSelectedRooms);
    const currentRoom = newRooms[roomIndex];

    if (currentRoom.childAges.length > children) {
      currentRoom.childAges = currentRoom.childAges.slice(0, children);
    } else if (currentRoom.childAges.length < children) {
      currentRoom.childAges = [
        ...currentRoom.childAges,
        ...Array(children - currentRoom.childAges.length).fill(undefined),
      ];
    }

    const validationErrors = validateRoom(currentRoom);

    if (
      currentRoom.validationMessage &&
      (!validationErrors || currentRoom.validationMessage !== validationErrors)
    ) {
      currentRoom.validationMessage = validationErrors;
    }

    setTempSelectedRooms(newRooms);
  };

  const changeChildAge = (roomIndex: number, childIndex: number, childAge: number) => {
    const newRooms = cloneRooms(tempSelectedRooms);
    const currentRoom = newRooms[roomIndex];

    currentRoom.childAges[childIndex] = childAge;
    const validationErrors = validateRoom(currentRoom);

    if (
      currentRoom.validationMessage &&
      (!validationErrors || currentRoom.validationMessage !== validationErrors)
    ) {
      currentRoom.validationMessage = validationErrors;
    }

    setTempSelectedRooms(newRooms);
  };

  const removeRoom = (roomIndex: number) =>
    setTempSelectedRooms(tempSelectedRooms.filter((room, i) => i !== roomIndex));

  const addRoom = () =>
    setTempSelectedRooms([
      ...tempSelectedRooms,
      {
        adults: 2,
        childAges: [],
        validationMessage: undefined,
      },
    ]);

  const applyRoomsSelection = (): boolean => {
    const newRooms = cloneRooms(tempSelectedRooms).map((room) => ({
      ...room,
      validationMessage: validateRoom(room),
    }));

    const hasErrors = newRooms.some((room) => room.validationMessage);
    setTempSelectedRooms(newRooms);
    if (!hasErrors) {
      const rooms = tempSelectedRooms.map((room) => ({
        adults: room.adults,
        childAges: [...room.childAges] as number[],
      }));
      setRooms(rooms);
    }

    return !hasErrors;
  };

  return {
    changeAdults,
    changeChildren,
    changeChildAge,
    addRoom,
    removeRoom,
    applyRoomsSelection,
    rooms,
    temporaryRooms: tempSelectedRooms,
  };
};
