import { Label, TriggerButton } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { ClassNameProps } from '@ComponentProps';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

export interface Option {
  label: string;
  value: string | number;
  disabled?: boolean;
  selected?: boolean;
}

interface NightsSelectorProps {
  onChange: () => void;
}

export const NightsSelector: React.FC<NightsSelectorProps & ClassNameProps> = ({
  onChange,
  className,
}) => {
  const { t } = useTranslation();

  const [selectedNights, setSelectedNights] = useSearchSelectionStore((state) => [
    state.nights,
    state.setNights,
  ]);

  const availableNights = useSearchAvailabilityStore((state) => state.nights);

  const options = availableNights.map(({ available, nights }) => ({
    value: nights,
    label: t('nightsLabel', { count: nights }),
    disabled: !available,
  }));

  return (
    <React.Fragment>
      <Label
        as="p"
        variant="mediumbold"
        sx={{
          padding: 's',
          display: ['none', 'block'],
        }}
      >
        {t('searchUi.nightsInput.selectorTitle')}
      </Label>
      <ul className={className}>
        {options.map((option) => (
          <li
            key={option.value.toString()}
            sx={{
              color: !option.disabled ? 'textDefault' : 'textDisabled',
              ...(selectedNights === option.value && {
                backgroundColor: 'backgroundBlack',
                borderColor: 'backgroundBlack',
                color: 'textLight',
              }),
              '&:hover, &:focus': {
                ...(selectedNights !== option.value &&
                  !option.disabled && {
                    backgroundColor: 'tagprimaryDefault',
                    borderColor: 'backgroundPrimarylight',
                  }),
              },
            }}
          >
            <TriggerButton
              disabled={option.disabled}
              data-id="nights-option"
              onTrigger={() => {
                setSelectedNights(option.value);
                onChange();
              }}
              sx={{
                width: '100%',
                height: '100%',
                paddingX: 's',
                paddingY: '3xs',
              }}
            >
              <Label variant="medium">{option.label}</Label>
            </TriggerButton>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};
