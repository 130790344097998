import {
  Label,
  Button,
  Stepper,
  DropdownSelector,
  Paragraph,
  hastToReact,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useEffect } from 'react';

import { maxNumberOfPassengersInARoom } from './constants';
import { TemporaryRoom } from './types';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import { ClassNameProps } from '@ComponentProps';
import { childAgeOptions } from '@Components/RoomsEditor/options';
import { RoomErrorMessages } from '@Components/RoomsEditor/RoomErrorMessages';
import { useAppContext } from '@Contexts/contexts';
import { sendEvent } from '@Core/tracking/sendEvent';
import { TrackingEvent } from '@Core/tracking/types';

interface GuestStepperProps extends ClassNameProps {
  label: string;
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
}

const GuestStepper: React.FC<GuestStepperProps> = ({
  label,
  value,
  min,
  max,
  onChange,
  'data-id': dataId,
}) => (
  <Label
    variant="small"
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginY: 'xs',
    }}
  >
    {label}
    <Stepper
      data-id={dataId}
      value={value}
      minValue={min}
      maxValue={max}
      onChange={onChange}
    />
  </Label>
);

export interface GuestRoomProps {
  roomIndex: number;
  room: TemporaryRoom;
  onNumberOfAdultsChange: (numberOfAdults: number, roomIndex: number) => void;
  onNumberOfChildrenChange: (numberOfChildren: number, roomIndex: number) => void;
  onChildAgeChange: (roomIndex: number, childIndex: number, childAge: number) => void;
  onRemoveClick: (roomIndex: number) => void;
}

export const GuestRoom: React.FC<GuestRoomProps> = ({
  roomIndex,
  room,
  onRemoveClick,
  onNumberOfAdultsChange,
  onNumberOfChildrenChange,
  onChildAgeChange,
}) => {
  const { site } = useAppContext();
  const { t } = useTranslation();

  const maxPaxNumber = site.salesPhoneNumbers?.tooManyPassengers;

  useEffect(() => {
    if (maxNumberOfPassengersInARoom === room.adults + room.childAges.length) {
      sendEvent({
        event: TrackingEvent.formError,
        type: RoomErrorMessages.TOO_MANY_PASSENGERS,
        party: `${room.adults} adults ${room.childAges.length} children`,
        partyComposition: {
          adults: room.adults,
          children: room.childAges.length,
        },
      });
    }
  }, [room]);

  return (
    <div
      data-id="guest-room"
      sx={{
        borderWidth: 1,
        borderColor: 'strokeLightsubtle',
        borderRadius: '8',
        borderStyle: 'solid',
        padding: 'l',
        marginBottom: 'xs',
      }}
    >
      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Label variant="mediumbold">{t('rooms.roomLabel', { index: roomIndex + 1 })}</Label>
        {roomIndex > 0 && (
          <Button
            data-id="room-remove-btn"
            variant="Secondary"
            size="36"
            icon="Actions/Remove"
            onClick={() => onRemoveClick(roomIndex)}
            ariaLabel={t('remove')}
            sx={{
              marginLeft: 's',
            }}
          />
        )}
      </div>
      <GuestStepper
        data-id={`adult-stepper-${roomIndex}`}
        label={t('rooms.adultsLabel')}
        value={room.adults}
        min={1}
        max={maxNumberOfPassengersInARoom - room.childAges.length}
        onChange={(adults: number) => onNumberOfAdultsChange(adults, roomIndex)}
      />
      <GuestStepper
        data-id={`child-stepper-${roomIndex}`}
        label={t('rooms.childrenLabel')}
        value={room.childAges.length}
        min={0}
        max={maxNumberOfPassengersInARoom - room.adults}
        onChange={(children: number) => onNumberOfChildrenChange(children, roomIndex)}
      />
      {!!room.childAges.length && (
        <Label
          variant="small"
          sx={{
            display: 'block',
            marginTop: 'l',
          }}
        >
          {t('rooms.childAgesLabel', { count: room.childAges.length })}
        </Label>
      )}
      {room.childAges.map((childAge, childIndex) => (
        <Label
          key={childIndex}
          as="label"
          variant="small"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginY: 'xs',
          }}
        >
          {t('rooms.childAgeLabelWithCounter', { index: childIndex + 1 })}
          <DropdownSelector
            data-id="child-age-select"
            variant="Light"
            size="36"
            options={childAgeOptions}
            value={childAge?.toString() || ''}
            onChange={(event) =>
              onChildAgeChange(roomIndex, childIndex, parseInt(event.target.value, 10))
            }
            selectStyles={{
              fontSize: 'm',
              ...(room.validationMessage && {
                borderColor: 'strokeCriticaldark',
              }),
            }}
          />
        </Label>
      ))}
      {room.validationMessage && <ValidationMessage>{room.validationMessage}</ValidationMessage>}
      {maxNumberOfPassengersInARoom === room.adults + room.childAges.length &&
        maxPaxNumber?.placementText && (
          <Paragraph variant="small">{hastToReact(maxPaxNumber?.placementText)}</Paragraph>
        )}
    </div>
  );
};
