import { Icon, Label } from '@loveholidays/design-system';
import React from 'react';

export interface ValidationMessageProps {
  children: string;
}

export const ValidationMessage: React.FC<ValidationMessageProps> = ({ children }) => (
  <div
    sx={{
      display: 'flex',
      color: 'textCriticaldark',
    }}
  >
    <Icon
      sx={{
        marginRight: '3xs',
      }}
      name="Markers/AlertFilled"
      size="16"
    />
    <Label variant="small">{children}</Label>
  </div>
);
