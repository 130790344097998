import {
  Icon,
  IconProps,
  Label,
  TriggerButton,
  TriggerEvent,
  ellipsis,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { forwardRef, Fragment } from 'react';

import { ClassNameProps } from '@ComponentProps';
import { SelectedTags } from '@UX/components/Search/SelectedTags/SelectedTags';

export interface SearchInputProps extends ClassNameProps {
  onClick: () => void;
  onClear?: (e: TriggerEvent) => void;
  prefix?: string;
  prefixIconName?: IconProps['name'];
  title?: string;
  label?: string;
  placeholder: string;
  tags?: {
    value: string;
    label: string;
  }[];
  removeTag?: (value: string) => void;
  showTitleOnMobile?: boolean;
}

export const SearchInput = forwardRef<HTMLDivElement, SearchInputProps>(
  (
    {
      'data-id': dataId,
      className,
      onClick,
      onClear,
      prefix,
      prefixIconName,
      placeholder,
      label,
      title,
      tags,
      trackingAction,
      eventLabel,
      showTitleOnMobile = false,
      removeTag,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const areTagsProvided = tags && tags.length > 0;

    if (!prefix && !prefixIconName) {
      throw new Error('One of prefix or prefixIconName must be provided');
    }

    if (prefix && prefixIconName) {
      throw new Error('Only one of prefix or prefixIconName must be provided');
    }

    if (label && areTagsProvided) {
      throw new Error('Cannot have label and tags');
    }

    const isFilled = label || areTagsProvided;

    return (
      <Fragment>
        {title && (
          <Label
            variant="medium"
            sx={{
              display: [showTitleOnMobile ? 'block' : 'none', 'block'],
              marginBottom: '3xs',
            }}
          >
            {title}
          </Label>
        )}
        <TriggerButton
          ref={ref}
          trackingAction={trackingAction}
          eventLabel={eventLabel}
          className={className}
          onTrigger={onClick}
          data-id={dataId}
          sx={{
            height: '48px',
            display: 'flex',
            alignItems: 'center',

            borderWidth: 'outlinedStrokeWeight',
            borderStyle: 'solid',
            borderColor: 'strokeDarkneutral',
            borderRadius: '8',
            paddingX: '2xs',
            backgroundColor: 'backgroundWhite',

            '&:hover': {
              borderColor: 'strokeDark',
            },
          }}
        >
          <div
            sx={{
              display: 'flex',
              marginRight: '3xs',
              ...(!isFilled && { color: 'textDimmedmedium' }),
            }}
          >
            {prefix && <Label variant="medium">{prefix}</Label>}

            {prefixIconName && (
              <Icon
                name={prefixIconName}
                size="20"
              />
            )}
          </div>

          {!isFilled && (
            <Label
              variant="medium"
              sx={{
                ...ellipsis,
                color: 'textDimmedmedium',
                marginRight: 'auto',
              }}
            >
              {placeholder}
            </Label>
          )}

          {label && (
            <Label
              variant="medium"
              sx={{
                ...ellipsis,
                marginRight: 'auto',
              }}
            >
              {label}
            </Label>
          )}

          {areTagsProvided && (
            <SelectedTags
              tags={tags}
              onTagClick={removeTag}
            />
          )}

          {isFilled && onClear && (
            <TriggerButton
              aria-label={t('remove')}
              data-id="search-input-close"
              onTrigger={onClear}
              sx={{
                display: 'flex',
              }}
            >
              <Icon
                name="Markers/ErrorFilled"
                size="20"
              />
            </TriggerButton>
          )}
        </TriggerButton>
      </Fragment>
    );
  },
);
